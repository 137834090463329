<script>
// import axios from 'axios'

  export default {
    name: 'Profile',
    metaInfo: {
      title: 'Profile',
    },
    components: {},
    data: () => ({
      dialog: false,
      isOpen: false,
      isBlock: false,
      title: '',
      text: '',
      color: '',
      items: [
        { text: 'Информация', icon: 'mdi-information', link: 'info' },
        { text: 'Основное', icon: 'mdi-account', link: 'main' },
        { text: 'Мои контакты', icon: 'mdi-card-account-phone' },
        { text: 'Документы', icon: 'mdi-file-document-multiple' },
        { text: 'Работа', icon: 'mdi-briefcase' },
        { text: 'Ссылки', icon: 'mdi-link' },
        { text: 'Доступ', icon: 'mdi-lock' },
        { text: 'Оформление', icon: 'mdi-pencil-ruler' },
        { text: 'Визитка', icon: 'mdi-smart-card' },
        { text: 'Кошелек', icon: 'mdi-wallet' },
      ],
    }),
    created () {
    // axios.get('/api/notes').then((response) => {
    //   this.notes = response.data
    // })
    // .catch(err => console.log(err))
    },
    methods: {
      go (to) {
        alert(to)
      },

    // saveNote: function () {
    //   axios
    //     .post('/api/notes/add', { data: this.newNote })
    //     .then((response) => {
    //       this.notes.push({
    //         title: this.newNote.title,
    //         text: this.newNote.text,
    //         color: this.newNote.color.hex,
    //       })
    //     })
    //   this.dialog = false
    // },
    //
    // deleteNote: function (note) {
    //   axios.post('/api/notes/delete', note).then((response) => {
    //     this.notes = this.notes.filter((x) => x.id !== note.id)
    //   })
    // },
    //
    // appToggle: function () {
    //   this.isOpen = !this.isOpen
    //   this.isBlock = !this.isBlock
    // },
    // overlayApp: function () {
    //   this.isOpen = !this.isOpen
    //   this.isBlock = !this.isBlock
    // },
    },
  }
</script>

<template>
  <div
    class="app-content-wrapper apps-wrapper "
  >
    <div
      :class="{open: isOpen}"
      class="app-sidebar"
    >
      <div class="app-sidebar-body">
        <nav>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                class="px-10"
                @click="go(item.link)"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-medium"
                    v-text="item.text"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </nav>
      </div>
    </div>
    <div class="app-content relative">
      <div class="app-body pt-0 px-8">
        <v-row>
          <v-col class="app-title _title">
            При длительной нагрузке кора прогибается; регрессия пододвигается под силурийский силл.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <article class="content-text">
              <p>Слово и дело.</p>

              <v-text-field
                v-model="email"
                label="Эл. почта"
                :rules="emailRules"
                required
              />
              <h2>
                Почему требуется согласование перепланировки квартиры?
              </h2>
              <p>
                Радиант дает <strong>возмущающий фактор</strong>. Нулевой меридиан <em>существенно отражает вращательный</em> эффективный диаметp.
                <a href="#visited">Посещенная ссылка</a> меняет <a href="#123">Непосещенная</a> далекий
                параметр.
              </p>
              <blockquote>
                Популяционный индекс изменяем. Скоpость кометы в пеpигелии на следующий год, когда было лунное затмение и сгорел древний храм Афины в Афинах (при эфоре Питии и афинском архонте
                Каллии), иллюстрирует восход , а оценить проницательную способность вашего телескопа поможет следующая формула: Mпр.= 2,5lg Dмм + 2,5lg Гкрат + 4. Параметр вызывает вращательный
                годовой параллакс. Различное расположение, а там действительно могли быть видны звезды, о чем свидетельствует Фукидид оценивает астероидный часовой угол. Приливное трение однородно
                отражает близкий апогей. Широта традиционно выбирает терминатор.
              </blockquote>

              <p>
                <a href="#">Дип-скай объект притягивает маятник Фуко.</a> Солнечное затмение, <a href="#">это удалось <strong>установить</strong> по</a> характеру спектра, традиционно иллюстрирует
                далекий параметр. Хотя хpонологи не увеpены, им кажется, что юлианская дата иллюстрирует первоначальный метеорит. Красноватая звездочка параллельна. Огpомная пылевая кома выбирает
                космический возмущающий фактор. Атомное время оценивает экваториальный болид , таким образом, атмосферы этих планет плавно переходят в жидкую мантию.
              </p>
              <h2>Почему некоторая пища «взрывается» в вашем желудке</h2>
              <p>
                <a href="#">Радиант дает возмущающий фактор. Нулевой меридиан существенно отражает вращательный эффективный диаметp.</a> <strong>Газопылевое облако меняет далекий параметр.</strong>
              </p>
              <p>
                Популяционный индекс изменяем. Скоpость кометы в пеpигелии на следующий год, когда было лунное затмение и сгорел древний храм Афины в Афинах (при эфоре Питии и афинском архонте
                Каллии), иллюстрирует восход , а оценить проницательную способность вашего телескопа поможет следующая формула: Mпр.= 2,5lg Dмм + 2,5lg Гкрат + 4. Параметр вызывает вращательный
                годовой параллакс. Различное расположение, а там действительно могли быть видны звезды, о чем свидетельствует Фукидид оценивает астероидный часовой угол. Приливное трение однородно
                отражает близкий апогей. Широта традиционно выбирает терминатор.
              </p>
              <p>
                У планет-гигантов нет твёрдой поверхности, таким образом приливное трение иллюстрирует метеорный дождь. У планет-гигантов нет твёрдой поверхности, таким образом отвесная линия
                оценивает эффективный диаметp, и в этом вопросе достигнута такая точность расчетов, что, начиная с того дня, как мы видим, указанного Эннием и записанного в "Больших анналах", было
                вычислено время предшествовавших затмений солнца, начиная с того, которое в квинктильские ноны произошло в царствование Ромула. Юлианская дата ищет Юпитер. Орбита, и это следует
                подчеркнуть, меняет космический pадиотелескоп Максвелла, а оценить проницательную способность вашего телескопа поможет следующая формула: <sup>Mпр.= 2,5lg Dмм + 2,5lg Гкрат + 4.</sup>
              </p>
              <h3>Почему некоторая пища «взрывается» в вашем желудке</h3>
              <p>
                Гетерономная этика понимает под собой напряженный гедонизм, не учитывая мнения авторитетов. Мир преобразует закон
                исключённого третьего, отрицая очевидное. Позитивизм подрывает типичный дуализм, не учитывая мнения авторитетов.
                Надо сказать, что знак творит естественный гравитационный парадокс, tertium nоn datur.
              </p>

              <h4>А у вас</h4>
              <p>
                Гетерономная этика понимает под собой напряженный гедонизм, не учитывая мнения авторитетов. Мир преобразует закон
                исключённого третьего, отрицая очевидное. Позитивизм подрывает типичный дуализм, не учитывая мнения авторитетов.
                Надо сказать, что знак творит естественный гравитационный парадокс, tertium nоn datur.
              </p>

              <h5>Почему некоторая пища «взрывается» в вашем желудке</h5>
              <p>
                Гетерономная этика понимает под собой напряженный гедонизм, не учитывая мнения авторитетов. Мир преобразует закон
                исключённого третьего, отрицая очевидное. Позитивизм подрывает типичный дуализм, не учитывая мнения авторитетов.
                Надо сказать, что знак творит естественный гравитационный парадокс, tertium nоn datur.
              </p>

              <h6>Гедонизм представляет собой даосизм. Катарсис ментально подрывает язык образов. Надо сказать, что адаптация ментально подчеркивает онтологический бабувизм. </h6>
              <p>
                Гетерономная этика понимает под собой напряженный гедонизм, не учитывая мнения авторитетов. Мир преобразует закон
                исключённого третьего, отрицая очевидное. Позитивизм подрывает типичный дуализм, не учитывая мнения авторитетов.
                Надо сказать, что знак творит естественный гравитационный парадокс, tertium nоn datur.
              </p>

              <hr>
              <p>
                Гетерономная этика понимает под собой напряженный гедонизм, не&nbsp;учитывая мнения авторитетов. Мир преобразует
                закон исключённого третьего, отрицая очевидное. Позитивизм подрывает типичный дуализм, не&nbsp;учитывая мнения
                авторитетов. Надо сказать, что знак творит естественный гравитационный парадокс, tertium nоn datur.
              </p>

              <ul>
                <li>Гедонизм представляет собой даосизм. Катарсис ментально подрывает язык образов. Надо сказать, что адаптация ментально подчеркивает онтологический бабувизм.</li>
                <li><p>Просто одно предложение.</p></li>
                <li>Гедонизм представляет собой даосизм. Катарсис ментально подрывает язык образов. Надо сказать, что адаптация ментально подчеркивает онтологический бабувизм.</li>
                <li>А у вас</li>
                <li>А у вас</li>
                <li>Найдите целое</li>
              </ul>

              <p>Просто одно предложение.</p>
              <ol>
                <li>Найдите целое</li>
                <li>Слово.</li>

                <li>Гедонизм представляет собой даосизм. Катарсис ментально подрывает язык образов. Надо сказать, что адаптация ментально подчеркивает онтологический бабувизм.</li>
                <li>
                  <p>
                    <a href="#">Дип-скай объект притягивает маятник Фуко.</a> Солнечное затмение, <a href="#">это удалось <strong>установить</strong> по</a> характеру спектра, традиционно
                    иллюстрирует далекий параметр. Хотя хpонологи не увеpены, им кажется, что юлианская дата иллюстрирует первоначальный метеорит. Красноватая звездочка параллельна. Огpомная пылевая
                    кома выбирает космический возмущающий фактор. Атомное время оценивает экваториальный болид , таким образом, атмосферы этих планет плавно переходят в жидкую мантию.
                  </p>
                </li>
                <li>Маленькая ошибка, которая обходится фермеру $3000 в год</li>
                <li>А у вас</li>
                <li>Слово <a href="#">и дело</a>.</li>
                <li>Как я улучшил свою память</li>
                <li>Кто еще хочет фигуру телезвезды?</li>
                <li>
                  <p>
                    <a href="#">Радиант дает возмущающий фактор. Нулевой меридиан существенно отражает вращательный эффективный диаметp.</a> <strong>Газопылевое облако меняет далекий
                      параметр.</strong>
                  </p>
                  <p>
                    Популяционный индекс изменяем. Скоpость кометы в пеpигелии на следующий год, когда было лунное затмение и сгорел древний храм Афины в Афинах (при эфоре Питии и афинском архонте
                    Каллии), иллюстрирует восход , а оценить проницательную способность вашего телескопа поможет следующая формула: Mпр.= 2,5lg Dмм + 2,5lg Гкрат + 4. Параметр вызывает вращательный
                    годовой параллакс. Различное расположение, а там действительно могли быть видны звезды, о чем свидетельствует Фукидид оценивает астероидный часовой угол. Приливное трение однородно
                    отражает близкий апогей. Широта традиционно выбирает терминатор.
                  </p>
                  <p>
                    У планет-гигантов нет твёрдой поверхности, таким образом приливное трение иллюстрирует метеорный дождь. У планет-гигантов нет твёрдой поверхности, таким образом отвесная линия
                    оценивает эффективный диаметp, и в этом вопросе достигнута такая точность расчетов, что, начиная с того дня, как мы видим, указанного Эннием и записанного в "Больших анналах", было
                    вычислено время предшествовавших затмений солнца, начиная с того, которое в квинктильские ноны произошло в царствование Ромула. Юлианская дата ищет Юпитер. Орбита, и это следует
                    подчеркнуть, меняет космический pадиотелескоп Максвелла, а оценить проницательную способность вашего телескопа поможет следующая формула: <sup>Mпр.= 2,5lg Dмм + 2,5lg Гкрат +
                      4.</sup>
                  </p>
                </li>
                <li>Секрет, который заставит людей вас полюбить</li>
                <li>Совет жены женам, мужья которых транжиры</li>
                <li>
                  <p>
                    <a href="#">Радиант дает возмущающий фактор. Нулевой меридиан существенно отражает вращательный эффективный диаметp.</a> <strong>Газопылевое облако меняет далекий
                      параметр.</strong>
                  </p>
                  <p>
                    Популяционный индекс изменяем. Скоpость кометы в пеpигелии на следующий год, когда было лунное затмение и сгорел древний храм Афины в Афинах (при эфоре Питии и афинском архонте
                    Каллии), иллюстрирует восход , а оценить проницательную способность вашего телескопа поможет следующая формула: Mпр.= 2,5lg Dмм + 2,5lg Гкрат + 4. Параметр вызывает вращательный
                    годовой параллакс. Различное расположение, а там действительно могли быть видны звезды, о чем свидетельствует Фукидид оценивает астероидный часовой угол. Приливное трение однородно
                    отражает близкий апогей. Широта традиционно выбирает терминатор.
                  </p>
                  <p>
                    У планет-гигантов нет твёрдой поверхности, таким образом приливное трение иллюстрирует метеорный дождь. У планет-гигантов нет твёрдой поверхности, таким образом отвесная линия
                    оценивает эффективный диаметp, и в этом вопросе достигнута такая точность расчетов, что, начиная с того дня, как мы видим, указанного Эннием и записанного в "Больших анналах", было
                    вычислено время предшествовавших затмений солнца, начиная с того, которое в квинктильские ноны произошло в царствование Ромула. Юлианская дата ищет Юпитер. Орбита, и это следует
                    подчеркнуть, меняет космический pадиотелескоп Максвелла, а оценить проницательную способность вашего телескопа поможет следующая формула: <sup>Mпр.= 2,5lg Dмм + 2,5lg Гкрат +
                      4.</sup>
                  </p>
                </li>
                <li>
                  <p>
                    Радиант дает <strong>возмущающий фактор</strong>. Нулевой меридиан <em>существенно отражает вращательный</em> эффективный диаметp. <a href="#">Газопылевое облако</a> меняет
                    далекий параметр.
                  </p>
                  <p>
                    Популяционный индекс изменяем. Скоpость кометы в пеpигелии на следующий год, когда было лунное затмение и сгорел древний храм Афины в Афинах (при эфоре Питии и афинском архонте
                    Каллии), иллюстрирует восход , а оценить проницательную способность вашего телескопа поможет следующая формула: Mпр.= 2,5lg Dмм + 2,5lg Гкрат + 4. Параметр вызывает вращательный
                    годовой параллакс. Различное расположение, а там действительно могли быть видны звезды, о чем свидетельствует Фукидид оценивает астероидный часовой угол. Приливное трение однородно
                    отражает близкий апогей. Широта традиционно выбирает терминатор.
                  </p>
                </li>
                <li>Как я улучшил свою память</li>
              </ol>
              <p>Несворачиваемый список</p>
              <ul>
                <li>Найдите целое</li>
                <li>Секрет, который заставит людей вас полюбить</li>
              </ul>
              <hr>
              <p>
                Популяционный индекс изменяем. Скоpость кометы в пеpигелии на следующий год, когда было лунное затмение и сгорел древний храм Афины в Афинах (при эфоре Питии и афинском архонте
                Каллии), иллюстрирует восход , а оценить проницательную способность вашего телескопа поможет следующая формула: Mпр.= 2,5lg Dмм + 2,5lg Гкрат + 4. Параметр вызывает вращательный
                годовой параллакс. Различное расположение, а там действительно могли быть видны звезды, о чем свидетельствует Фукидид оценивает астероидный часовой угол. Приливное трение однородно
                отражает близкий апогей. Широта традиционно выбирает терминатор.
              </p>
              <div class="_alt">
                <p>
                  Популяционный индекс изменяем. <a href="#">Скоpость кометы</a> в пеpигелии на следующий год, когда было лунное затмение и сгорел древний храм Афины в Афинах (при эфоре Питии и афинском архонте
                  Каллии), иллюстрирует восход , а оценить проницательную способность вашего телескопа поможет следующая формула: Mпр.= 2,5lg Dмм + 2,5lg Гкрат + 4. Параметр вызывает вращательный
                  годовой параллакс. Различное расположение, а там действительно могли быть видны звезды, о чем свидетельствует Фукидид оценивает астероидный часовой угол. Приливное трение однородно
                  отражает близкий апогей. Широта традиционно выбирает терминатор.
                </p>
              </div>
              <p>
                Популяционный индекс изменяем. Скоpость кометы в пеpигелии на следующий год, когда было лунное затмение и сгорел древний храм Афины в Афинах (при эфоре Питии и афинском архонте
                Каллии), иллюстрирует восход , а оценить проницательную способность вашего телескопа поможет следующая формула: Mпр.= 2,5lg Dмм + 2,5lg Гкрат + 4. Параметр вызывает вращательный
                годовой параллакс. Различное расположение, а там действительно могли быть видны звезды, о чем свидетельствует Фукидид оценивает астероидный часовой угол. Приливное трение однородно
                отражает близкий апогей. Широта традиционно выбирает терминатор.
              </p>
              <a
                href="https://soglas-proekt.ru/files/node-238_param-img.jpg"
                class="_img"
              ><img
                src="https://soglas-proekt.ru/files/node-238_param-img.jpg"
                alt="Согласование перепланировки квартиры"
              ></a>
              Слово и дело.
              <ul>
                <li><a href="1">Секрет, который заставит людей вас полюбить</a></li>
                <li><a href="12">Совет жены женам, мужья которых транжиры</a></li>
                <li>
                  <a href="12">Список 3 Грязелечение в стоматологии Грязелечение в стоматологии Грязелечение в стоматологии</a>
                </li>
                <li>Список 4 Грязелечение в стоматологии</li>
                <li><a href="123">Список 5</a></li>
              </ul>
              <p>
                Гетерономная этика понимает под собой напряженный гедонизм, не учитывая мнения авторитетов. Мир преобразует закон
                исключённого третьего, отрицая очевидное. Позитивизм подрывает типичный дуализм, не учитывая мнения авторитетов.
                Надо сказать, что знак творит естественный гравитационный парадокс, tertium nоn datur.
              </p>
              <ul>
                <li>Найдите целое</li>
                <li>Вы можете смеяться над финансовым беспокойством, если будете следовать этому простому плану</li>
                <li>Последние 2 часа самые длинные, – и это те часы, которые вы не теряете</li>
              </ul>
              Слово и дело.
              <hr>
              <h2><a href="#">Что-то</a> как-будто где-то</h2>
              <p>
                <img src="https://loremflickr.com/1280/640">Гетерономная этика понимает под собой напряженный гедонизм, не&nbsp;учитывая мнения авторитетов. Мир преобразует
                закон исключённого третьего, отрицая очевидное. Позитивизм подрывает типичный дуализм, не&nbsp;учитывая мнения
                авторитетов. Надо сказать, что знак творит естественный гравитационный парадокс, tertium nоn datur.
              </p>

              <p>
                <img
                  src="https://loremflickr.com/150/150"
                  style="float: left;"
                >ности, ломая рамки привычных представлений. Реальность нетривиальна. Надо сказать, что
                дилемма философски осмысляет конфликт, при этом буквы А,&nbsp;В,&nbsp;I,&nbsp;О символизируют соответственно
                общеутвердительное, общеотрица
              </p>

              <p>
                <img
                  src="https://loremflickr.com/150/150"
                  style="float: right;"
                >Созерцание <a href="#">осмысленно трансформирует</a> примитивный интеллект, при этом буквы А,&nbsp;В,&nbsp;I,&nbsp;О
                символизируют соответственно общеутвердительное, общеотрицательное, частноутвердительное и&nbsp;частноотрицательное
                суждения. Отсюда <em>естественно</em> следует, что <strong>аналогия</strong> методологически создает из&nbsp;ряда
                вон выходящий предмет деятельности, ломая рамки привычных представлений. Реальность нетривиальна. Надо сказать, что
                дилемма философски осмысляет конфликт, привердительное и&nbsp;частноотрицательное суждения. Язык
                образов, конечно, рассматривается бабувизм, tertium nоn datur.
              </p>

              <blockquote class="block">
                <h1>Съешь еще этих мягких, французских<br>булок, да выпей чаю</h1>

                <h2><a href="#">Что-то</a> как-будто где-то</h2>
                <p>
                  Гетерономная этика понимает под собой напряженный гедонизм, не учитывая мнения авторитетов. Мир преобразует закон
                  исключённого третьего, отрицая очевидное. Позитивизм подрывает типичный дуализм, не учитывая мнения авторитетов.
                  Надо сказать, что знак творит естественный гравитационный парадокс, tertium nоn datur.
                </p>

                <h3>Заголовок <br>средний</h3><a href="#a5">Физиотерапия в стоматологии</a>

                <p>
                  Гетерономная этика понимает под собой напряженный гедонизм, не учитывая мнения авторитетов. Мир преобразует закон
                  исключённого третьего, отрицая очевидное. Позитивизм подрывает типичный дуализм, не учитывая мнения авторитетов.
                  Надо сказать, что знак творит естественный гравитационный парадокс, tertium nоn datur.
                </p>
                <button>Не знаю</button>
              </blockquote>

              <p>
                Гетерономная этика понимает под собой напряженный гедонизм, не&nbsp;учитывая мнения авторитетов. Мир преобразует
                закон исключённого третьего,
                <!--button type="button" data-toggle="modal" data-target="#myModal" class="btn btn-xs btn-primary">открыть окно</button-->
                отрицая очевидное. Позитивизм подрывает типичный дуализм, не&nbsp;учитывая мнения
                авторитетов. Надо сказать, что знак творит естественный гравитационный парадокс, tertium nоn datur.
              </p>

              <!--fieldset>
                  <legend>Клиент</legend>

                  <div class="line">
                      <div class="title">Имя клиента</div>
                      <input type="text" placeholder="Фамилия" class="required" title="Фамилия" />
                      <input type="text" placeholder="Имя" class="required" />
                      <input type="text" placeholder="Отчество" />
                  </div>

                  <div class="line">
                      <div class="title">Телефон клиента</div>
                      +<input type="text" size="1" placeholder="Страна" title="Код страны" value="7" /><input type="text" size="3" placeholder="Код"  title="Код оператора/города" />
                      <input type="text" size="15" placeholder="Номер"  class="required" title="" />
                  </div>

                  <div class="line">
                      <input type="text" size="20" placeholder="E-mail" />
                  </div><p>Закон внешнего мира порождает и&nbsp;обеспечивает сложный знак, при этом буквы А,&nbsp;В,&nbsp;I,&nbsp;О
                  символизируют соответственно общеутвердительное, общеотрицательное, частноутвердительное и&nbsp;частноотрицательное
                  суждения. Дилемма, как принято считать, методологически контролирует типичный конфликт, tertium nоn datur.
                  Искусство, как следует из&nbsp;вышесказанного, амбивалентно. Гносеология, как принято считать, непредвзято
                  подчеркивает катарсис, хотя в&nbsp;официозе принято обратное. Гедонизм, как принято считать, рассматривается
                  субъективный гений, ломая рамки привычных представлений.</p>
              </fieldset>

              <fieldset>
                  <legend><a href="#" class="interactive">Дополнительная информация</a></legend>

                  <div class="line">
                      <input type="text" size="20" placeholder="E-mail" />
                  </div>
                  <div class="line">
                      <input type="text" size="20" placeholder="E-mail" />
                  </div>
                  <div class="line">
                      <textarea placeholder="Комментарий" class="required"></textarea>
                  </div>
              </fieldset-->

              <!--    -->
            </article>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-content-wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 200px);

  .app-overlay {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: none;
    z-index: 2;
    transition: all 0.3s ease-in;
    @media only screen and (max-width: 959px) {
      &.open {
        display: block;
        transition: all 0.3s ease-in;
      }
    }
  }

  .app-sidebar {
    width: 280px;
    position: relative;
    transition: all 0.3s ease-in;
    @media only screen and (max-width: 959px) {
      position: absolute;
      left: -340px;
      z-index: 5;
      height: calc(100vh - 120px);
      transition: all 0.3s ease-in;
      &.open {
        left: 0;
      }
    }

    .app-sidebar-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .app-sidebar-body {
      // overflow-y: scroll;
      // height: 100%;
      padding-top: 3.375rem;
      width: 280px;
    }
  }

  .app-content {
    width: calc(100% - 280px);
    @media only screen and (max-width: 959px) {
      width: 100%;
    }
    // margin-left: 30px;
    .app-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .app-body {
      flex: 1 1 auto;
      padding-top: 3.375rem;
      // overflow-y: scroll;
      height: calc(100% - 130px);
    }

    .app-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 130px;
    }
  }
}

.app-icon {
  display: none;
  @media only screen and (max-width: 959px) {
    display: block;
  }
}

.eg-filemanager {
  background-color: #fff;
}

.filemanager-content {
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
  &.--filemanager {
    .nested-sidebar-toggle {
      @media (min-width: 959px) {
        display: none;
      }
    }
  }
}
</style>
